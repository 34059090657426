
  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import SearchEvent from "@/components/SearchEvent.vue";
  import { namespace } from "vuex-class";
  import { api as Events } from "../api";
  import { ROUTES } from "../router";
  import DesignModal from "../components/DesignModal.vue";
  import LzModal from "@/components/Modal.vue";
  import SectionToggle from "@/components/SectionToggle.vue";
  const auth = namespace("auth");

  @Component({
    components: { LzButton, SearchEvent, DesignModal, LzModal, SectionToggle }
  })
  export default class Read extends Vue {
    @auth.State("id")
    public memberId!: string;
    public events: CalendarEvent[] = [];
    deleteModalOpen = false;

    async mounted() {
      await this.loadEvents();
    }

    async loadEvents() {
      if (!this.memberId) return;
      const events = await Events.getAllByMemberId(this.memberId);
      if (events) {
        this.events = events;
      }
    }

    query = "";
    get searchResults() {
      return this.events.filter(event =>
        event.name.toLowerCase().includes(this.query.toLowerCase())
      );
    }
    handleSearch(text: string) {
      this.query = text;
    }

    createEvent() {
      this.$router.push({
        name: ROUTES.CREATE
      });
    }

    edit(eventId: string) {
      this.$router.push({
        name: ROUTES.CREATE,
        params: { eventId }
      });
    }

    async confirm(): Promise<boolean> {
      this.deleteModalOpen = true;

      return new Promise(res => {
        this.$on("confirm", () => {
          this.deleteModalOpen = false;
          res(true);
        });
        this.$on("cancel", () => {
          this.deleteModalOpen = false;
          res(false);
        });
      });
    }

    async deleteEvent(id: string) {
      // If the user cancels, we don't want to delete the event
      if (!(await this.confirm())) return;

      Events.delete(id)
        .then(() => {
          this.$notify({
            type: "success",
            text: this.$tc("events.read.notifications.removed")
          });
          this.loadEvents();
        })
        .catch(() => {
          this.$notify({
            type: "error",
            text: this.$tc("common.error.generic")
          });
        });
    }
  }
